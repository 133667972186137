<header class="header-area">
    
    <!-- Start Top Header -->
    <div class="top-header">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-12">
                    <!-- <ul class="top-header-nav">
                        <li><a routerLink="/">Career</a></li>
                        <li><a routerLink="/">Our Solutions</a></li>
                        <li><a routerLink="/">Claims</a></li>
                    </ul> -->
                </div>

                <div class="col-lg-7 col-md-12">
                    <div class="top-header-right-side">
                        <ul>
                            <li>
                                <div class="icon">
                                    <i class="flaticon-email"></i>
                                </div>
                                <span>Drop us an email:</span>
                                <a href="mailto:dhruvildudhat88@gmail.com"
                                    >dhruvildudhat88@gmail.com</a
                                >
                            </li>

                            <li style="padding-left: 3rem;">
                                <div class="icon">
                                    <i class="flaticon-call"></i>
                                </div>
                                <span>Call us:</span>
                                <a href="tel:9728779158">+1 972-877-9158</a>
                            </li>

                            <!-- <li>
                                <a routerLink="/" class="default-btn"
                                    >Get A Quote <span></span
                                ></a>
                            </li> -->
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- End Top Header -->

    <!-- Start Navbar Area -->
    <div class="navbar-area">
        <div class="truz-responsive-nav">
            <div class="container">
                <div class="truz-responsive-menu">
                    <div class="logo">
                        <!-- <a routerLink="/">
                            <img src="assets/img/logo.png" alt="logo" /> 
                            
                        </a> -->
                        <h2>Forever Life Secure</h2>
                    </div>
                </div>
            </div>
        </div>

        <div class="truz-nav">
            <div class="container">
                <nav class="navbar navbar-expand-md navbar-light">
                    <!-- <a class="navbar-brand" routerLink="/">
                        <img src="assets/img/logo.png" alt="logo" />
                    </a> -->
                    <h2>Forever Life Secure</h2>

                    <div
                        class="collapse navbar-collapse mean-menu"
                        id="navbarSupportedContent"
                    >
                        <ul class="navbar-nav">
                            <li class="nav-item">
                                <a
                                            routerLink="/home"
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >Home</a
                                        >
                                <!-- <ul class="dropdown-menu">
                                    <li class="nav-item">
                                        <a
                                            routerLink="/"
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >Home One</a
                                        >
                                    </li>

                                    <li class="nav-item">
                                        <a
                                            routerLink="/home-two"
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >Home Two</a
                                        >
                                    </li>

                                    <li class="nav-item">
                                        <a
                                            routerLink="/home-three"
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >Home Three</a
                                        >
                                    </li>

                                    <li class="nav-item">
                                        <a
                                            routerLink="/home-four"
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >Home Four</a
                                        >
                                    </li>
                                </ul> -->
                            </li>

                            <li class="nav-item">
                                <a
                                    routerLink="/about"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    >About Us</a
                                >
                            </li>

                            <li class="nav-item">
                                <a href="javascript:void(0)" class="nav-link"
                                    >Insurance
                                    <i class="flaticon-down-arrow"></i
                                ></a>
                                <ul class="dropdown-menu">
                                    <!-- <li class="nav-item">
                                        <a
                                            routerLink="/home-insurance"
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >Home Insurance</a
                                        >
                                    </li> -->

                                    <!-- <li class="nav-item">
                                        <a
                                            routerLink="/business-insurance"
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >Business Insurance</a
                                        >
                                    </li> -->

                                    <li class="nav-item">
                                        <a
                                            routerLink="/health-insurance"
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >Health Insurance</a
                                        >
                                    </li>

                                    <!-- <li class="nav-item">
                                        <a
                                            routerLink="/travel-insurance"
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >Travel Insurance</a
                                        >
                                    </li> -->

                                    <!-- <li class="nav-item">
                                        <a
                                            routerLink="/car-insurance"
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >Car Insurance</a
                                        >
                                    </li> -->

                                    <li class="nav-item">
                                        <a
                                            routerLink="/life-insurance"
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >Life Insurance</a
                                        >
                                    </li>
                                </ul>
                            </li>


                            <!-- <li class="nav-item">
                                <a href="javascript:void(0)" class="nav-link"
                                    >Pages <i class="flaticon-down-arrow"></i
                                ></a>
                                <ul class="dropdown-menu">
                                     <li class="nav-item">
                                        <a
                                            routerLink="/team"
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >Team</a
                                        >
                                    </li> 

                                   <li class="nav-item">
                                        <a
                                            href="javascript:void(0)"
                                            class="nav-link"
                                            >Services</a
                                        >
                                        <ul class="dropdown-menu">
                                            <li class="nav-item">
                                                <a
                                                    routerLink="/services-one"
                                                    class="nav-link"
                                                    routerLinkActive="active"
                                                    [routerLinkActiveOptions]="{
                                                        exact: true
                                                    }"
                                                    >Services Style 1</a
                                                >
                                            </li>

                                            <li class="nav-item">
                                                <a
                                                    routerLink="/services-two"
                                                    class="nav-link"
                                                    routerLinkActive="active"
                                                    [routerLinkActiveOptions]="{
                                                        exact: true
                                                    }"
                                                    >Services Style 2</a
                                                >
                                            </li>
                                        </ul>
                                    </li> 

                                    <li class="nav-item">
                                        <a
                                            href="javascript:void(0)"
                                            class="nav-link"
                                            >Case Study</a
                                        >
                                        <ul class="dropdown-menu">
                                            <li class="nav-item">
                                                <a
                                                    routerLink="/case-study"
                                                    class="nav-link"
                                                    routerLinkActive="active"
                                                    [routerLinkActiveOptions]="{
                                                        exact: true
                                                    }"
                                                    >Case Study</a
                                                >
                                            </li>

                                            <li class="nav-item">
                                                <a
                                                    routerLink="/case-study-details"
                                                    class="nav-link"
                                                    routerLinkActive="active"
                                                    [routerLinkActiveOptions]="{
                                                        exact: true
                                                    }"
                                                    >Case Study Details</a
                                                >
                                            </li>
                                        </ul>
                                    </li>

                                    <li class="nav-item">
                                        <a
                                            href="javascript:void(0)"
                                            class="nav-link"
                                            >Events</a
                                        >
                                        <ul class="dropdown-menu">
                                            <li class="nav-item">
                                                <a
                                                    routerLink="/events"
                                                    class="nav-link"
                                                    routerLinkActive="active"
                                                    [routerLinkActiveOptions]="{
                                                        exact: true
                                                    }"
                                                    >Events</a
                                                >
                                            </li>

                                            <li class="nav-item">
                                                <a
                                                    routerLink="/events-details"
                                                    class="nav-link"
                                                    routerLinkActive="active"
                                                    [routerLinkActiveOptions]="{
                                                        exact: true
                                                    }"
                                                    >Events Details</a
                                                >
                                            </li>
                                        </ul>
                                    </li>

                                    <li class="nav-item">
                                        <a
                                            routerLink="/pricing"
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >Pricing</a
                                        >
                                    </li>

                                    <li class="nav-item">
                                        <a
                                            routerLink="/testimonials"
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >Testimonials</a
                                        >
                                    </li>

                                    <li class="nav-item">
                                        <a
                                            routerLink="/faq"
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >FAQ</a
                                        >
                                    </li>

                                    <li class="nav-item">
                                        <a
                                            routerLink="/error"
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >404 Error Page</a
                                        >
                                    </li>
                                </ul>
                            </li> -->

                            <!-- <li class="nav-item">
                                <a href="javascript:void(0)" class="nav-link"
                                    >Blog <i class="flaticon-down-arrow"></i
                                ></a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item">
                                        <a
                                            routerLink="/blog-grid"
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >Blog Grid</a
                                        >
                                    </li>

                                    <li class="nav-item">
                                        <a
                                            routerLink="/blog-right-sidebar"
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >Blog Right Sidebar</a
                                        >
                                    </li>

                                    <li class="nav-item">
                                        <a
                                            routerLink="/blog-details"
                                            class="nav-link"
                                            routerLinkActive="active"
                                            [routerLinkActiveOptions]="{
                                                exact: true
                                            }"
                                            >Blog Details</a
                                        >
                                    </li>
                                </ul>
                            </li> -->

                            <li class="nav-item">
                                <a
                                    routerLink="/contact"
                                    class="nav-link"
                                    routerLinkActive="active"
                                    [routerLinkActiveOptions]="{ exact: true }"
                                    >Contact</a
                                >
                            </li>
                        </ul>

                        <div class="others-option">
                            <!-- <div class="option-item">
                                <i class="search-btn flaticon-search"></i>
                                <i class="close-btn flaticon-cross-out"></i>

                                <div class="search-overlay search-popup">
                                    <div class="search-box">
                                        <form class="search-form">
                                            <input
                                                class="search-input"
                                                name="search"
                                                placeholder="Search"
                                                type="text"
                                            />

                                            <button
                                                class="search-button"
                                                type="submit"
                                            >
                                                <i class="flaticon-search"></i>
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div> -->

                            <div class="burger-menu">
                                <i class="flaticon-menu"></i>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    </div>
    <!-- End Navbar Area -->
</header>
