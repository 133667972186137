import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeFourComponent } from './components/pages/home-four/home-four.component';
import { AboutComponent } from './components/pages/about/about.component';
import { TeamComponent } from './components/pages/team/team.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { HealthInsuranceComponent } from './components/pages/health-insurance/health-insurance.component';
// import { HeaderOneComponent } from './components/pages/header-one/header-one.component';
import { LifeInsuranceComponent } from './components/pages/life-insurance/life-insurance.component';

const routes: Routes = [
    {path: 'home', component: HomeFourComponent},
    {path: 'about', component: AboutComponent},
    {path: 'team', component: TeamComponent},
    {path: 'health-insurance', component: HealthInsuranceComponent},
    {path: 'life-insurance', component: LifeInsuranceComponent},

    {path: 'faq', component: FaqComponent},
    {path: 'error', component: ErrorComponent},
    {path: 'contact', component: ContactComponent},
    {path: '**', component: HomeFourComponent},
    // {path: '/', component: HeaderOneComponent}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }