<app-header-one></app-header-one>

<div class="page-title-area page-title-bg3">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>Contact</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>Contact</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="contact-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Message Us</span>
            <h2>Drop us Message for any Query</h2>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="contact-form">
                    <form id="contactForm">
                        <div class="row">
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group mb-3">
                                    <input
                                        type="text"
                                        name="name"
                                        class="form-control"
                                        placeholder="Name"
                                    />
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-6">
                                <div class="form-group mb-3">
                                    <input
                                        type="email"
                                        name="email"
                                        class="form-control"
                                        placeholder="Email"
                                    />
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <input
                                        type="text"
                                        name="phone-number"
                                        class="form-control"
                                        placeholder="Phone"
                                    />
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group mb-3">
                                    <textarea
                                        name="message"
                                        class="form-control"
                                        cols="30"
                                        rows="6"
                                        placeholder="Your Message"
                                    ></textarea>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="default-btn">
                                    Send Message <span></span>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div id="map">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d836.8562616072978!2d-96.7267752!3d32.9661861!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864c1f3fe88be509%3A0x8721b62247d20c23!2s1202%20Richardson%20Dr%20%23309%2C%20Richardson%2C%20TX%2075080!5e0!3m2!1sen!2sus!4v1697086235325!5m2!1sen!2sus"
                        width="600"
                        height="450"
                        style="border: 0"
                        allowfullscreen=""
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                    ></iframe>
                </div>
            </div>
        </div>

        <div class="contact-info">
            <div class="contact-info-content">
                <h3>Contact us by Phone Number or Email Address</h3>
                <h2>
                    <a href="tel:+0881306298615">+972 877 9158</a>
                    <span>OR</span>
                    <a href="mailto:dhruvildudhat88@gmail.com">dhruvildudhat88@gmail.com</a>
                </h2>
                <ul class="social">
                    <li>
                        <a href="#" target="_blank"
                            ><i class="fab fa-twitter"></i
                        ></a>
                    </li>
                    <li>
                        <a href="#" target="_blank"
                            ><i class="fab fa-youtube"></i
                        ></a>
                    </li>
                    <li>
                        <a href="#" target="_blank"
                            ><i class="fab fa-facebook-f"></i
                        ></a>
                    </li>
                    <li>
                        <a href="#" target="_blank"
                            ><i class="fab fa-linkedin-in"></i
                        ></a>
                    </li>
                    <li>
                        <a href="#" target="_blank"
                            ><i class="fab fa-instagram"></i
                        ></a>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <div class="bg-map"><img src="assets/img/bg-map.png" alt="image" /></div>
</section>
