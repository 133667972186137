<app-header-one></app-header-one>

<div class="page-title-area page-title-bg2">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-title-content">
                    <h2>FAQ</h2>
                    <ul>
                        <li><a routerLink="/">Home</a></li>
                        <li>FAQ</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="faq-area ptb-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Frequently Asked Questions</span>
            <h2>Get Every Single Answers There if you want</h2>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="faq-item">
                    <h3>Q: What is personal liability umbrella coverage?</h3>
                    <p><strong>Ans:</strong> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan.</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="faq-item">
                    <h3>Q: How do I print my Auto insurance ID card?</h3>
                    <p><strong>Ans:</strong> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan.</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="faq-item">
                    <h3>Q: What kinds of Auto insurance discounts are available?</h3>
                    <p><strong>Ans:</strong> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan.</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="faq-item">
                    <h3>Q: How do I know what deductible applies to my policy?</h3>
                    <p><strong>Ans:</strong> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan.</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="faq-item">
                    <h3>Q: If I am moving, will I need to request a new agent?</h3>
                    <p><strong>Ans:</strong> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan.</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="faq-item">
                    <h3>Q: How does this plan work?</h3>
                    <p><strong>Ans:</strong> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan.</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="faq-item">
                    <h3>Q: How many times will be charged?</h3>
                    <p><strong>Ans:</strong> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan.</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="faq-item">
                    <h3>Q: How long do I have access?</h3>
                    <p><strong>Ans:</strong> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan.</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="faq-contact-area ptb-100 pt-0">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Message Us</span>
            <h2>Do You Have Any Questions</h2>
            <p>If you have an idea, we would love to hear about it.</p>
        </div>

        <div class="faq-contact-form">
            <form id="contactForm">
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <div class="form-group mb-3">
                            <input type="text" name="name" class="form-control" placeholder="Name">
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="form-group mb-3">
                            <input type="email" name="email" class="form-control" placeholder="Email">
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <div class="form-group mb-3">
                            <input type="text" name="phone-number" class="form-control" placeholder="Phone">
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <div class="form-group mb-3">
                            <textarea name="message" class="form-control" cols="30" rows="6" placeholder="Your Message"></textarea>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <button type="submit" class="default-btn">Send Message <span></span></button>
                    </div>
                </div>
            </form>
        </div>
    </div>
    
    <div class="bg-map"><img src="assets/img/bg-map.png" alt="image"></div>
</section>