<footer class="footer-area">
    <div class="container">
        <div class="subscribe-area">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-12">
                    <div class="subscribe-content">
                        <h2>Join Our Newsletter</h2>
                        <!-- <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt.
                        </p> -->
                    </div>
                </div>

                <div class="col-lg-7 col-md-12">
                    <div class="subscribe-form">
                        <form class="newsletter-form">
                            <input
                                type="email"
                                class="input-newsletter"
                                placeholder="Enter your email address"
                                name="EMAIL"
                                required
                                autocomplete="off"
                            />
                            <button type="submit">
                                Subscribe Now
                                <i class="flaticon-right-chevron"></i>
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <div class="logo">
                        
                           <h3>Forever Life Secure</h3>

                        <!-- <p>
                            Lorem ipsum dolor sit amet, consectetur elit, sed do
                            eiusmod tempor ut labore et dolore magna aliqua.
                            Quis ipsum suspendisse ultrices gravida. Risus
                            commodo viverra lacus vel facilisis.
                        </p> -->
                    </div>

                    <ul class="social">
                        <li>
                            <a href="#" target="_blank"
                                ><i class="fab fa-facebook-f"></i
                            ></a>
                        </li>
                        <li>
                            <a href="#" target="_blank"
                                ><i class="fab fa-twitter"></i
                            ></a>
                        </li>
                        <li>
                            <a href="#" target="_blank"
                                ><i class="fab fa-linkedin-in"></i
                            ></a>
                        </li>
                        <li>
                            <a href="#" target="_blank"
                                ><i class="fab fa-instagram"></i
                            ></a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Quick Links</h3>

                    <ul class="footer-quick-links">
                        <li><a routerLink="/">Home</a></li>
                        <!-- <li><a routerLink="/">Our Team</a></li> -->
                        <li><a routerLink="/">About Us</a></li>
                        <!-- <li><a routerLink="/">Latest News</a></li> -->
                        <li><a routerLink="/">Insurance</a></li>
                        <li><a routerLink="/">Privacy Policy</a></li>
                        <!-- <li><a routerLink="/">Our Events</a></li> -->
                        <li><a routerLink="/">Terms & Conditions</a></li>
                        <!-- <li><a routerLink="/">Our Packages</a></li> -->
                        <li><a routerLink="/">Contact Us</a></li>
                    </ul>
                </div>
            </div>

            <div
                class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-sm-3 offset-md-3"
            >
                <div class="single-footer-widget">
                    <h3>Contact Info</h3>

                    <ul class="footer-contact-info">
                        <li>
                            <span>Location:</span>
                            <a href="https://www.google.com/maps/place/1202+Richardson+Dr+%23309,+Richardson,+TX+75080/@32.9661861,-96.7267752,19z/data=!4m5!3m4!1s0x864c1f3fe88be509:0x8721b62247d20c23!8m2!3d32.9661876!4d-96.7267271?entry=ttu"
                                target="_blank">1202 Richardson Dr #309, Richardson, TX - 75080</a
                            >
                        </li>
                        <li>
                            <span>Email:</span>
                            <a href="mailto:dhruvildudhat88@gmail.com">dhruvildudhat88@gmail.com</a>
                        </li>
                        <li>
                            <span>Phone:</span>
                            <a href="tel:9728779158">+(972) 877 9158</a>
                        </li>
                        <!-- <li>
                            <span>Fax:</span>
                            <a href="tel:12129876543">+1-212-9876543</a>
                        </li> -->
                        <li>
                            <a
                                href="https://www.google.com/maps/place/1202+Richardson+Dr+%23309,+Richardson,+TX+75080/@32.9661861,-96.7267752,19z/data=!4m5!3m4!1s0x864c1f3fe88be509:0x8721b62247d20c23!8m2!3d32.9661876!4d-96.7267271?entry=ttu"
                                target="_blank"
                                >View Location on GoogleMap</a
                            >
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="copyright-area">
            <div class="row align-items-center">
                <div class="col-lg-6 col-sm-6 col-md-6">
                    <p>
                        © Forever Life Secure
                        <!-- <a href="https://envytheme.com/" target="_blank"
                            >EnvyTheme</a
                        > -->
                    </p>
                </div>

                <div class="col-lg-6 col-sm-6 col-md-6">
                    <ul>
                        <li><a routerLink="/">Privacy Policy</a></li>
                        <li><a routerLink="/">Terms & Conditions</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>

<div class="go-top">
    <i class="fas fa-chevron-up"></i><i class="fas fa-chevron-up"></i>
</div>

<div class="sidebar-modal">
    <div class="sidebar-modal-inner">
        <div class="sidebar-about-area">
            <div class="title">
                <h2>About Us</h2>
                <p>
                    We believe brand interaction is key in communication. Real
                    innovations and a positive customer experience are the heart
                    of successful communication. No fake products and services.
                    The customer is king, their lives and needs are the
                    inspiration.
                </p>
            </div>
        </div>

        <div class="sidebar-instagram-feed">
            <h2>Instagram</h2>

            <ul>
                <li>
                    <a href="#"
                        ><img src="assets/img/blog-image/img1.jpg" alt="image"
                    /></a>
                </li>
                <li>
                    <a href="#"
                        ><img src="assets/img/blog-image/img2.jpg" alt="image"
                    /></a>
                </li>
                <li>
                    <a href="#"
                        ><img src="assets/img/blog-image/img3.jpg" alt="image"
                    /></a>
                </li>
                <li>
                    <a href="#"
                        ><img src="assets/img/blog-image/img4.jpg" alt="image"
                    /></a>
                </li>
                <li>
                    <a href="#"
                        ><img src="assets/img/blog-image/img5.jpg" alt="image"
                    /></a>
                </li>
                <li>
                    <a href="#"
                        ><img src="assets/img/blog-image/img6.jpg" alt="image"
                    /></a>
                </li>
                <li>
                    <a href="#"
                        ><img src="assets/img/blog-image/img7.jpg" alt="image"
                    /></a>
                </li>
                <li>
                    <a href="#"
                        ><img src="assets/img/blog-image/img8.jpg" alt="image"
                    /></a>
                </li>
            </ul>
        </div>

        <div class="sidebar-contact-area">
            <div class="sidebar-contact-info">
                <div class="contact-info-content">
                    <h2>
                        <a href="tel:0881306298615">+1 972 877 9158</a>
                        <span>OR</span>
                        <a href="mailto:dhruvildudhat88@gmail.com">dhruvildudhat88@gmail.com</a>
                    </h2>

                    <ul class="social">
                        <li>
                            <a href="#" target="_blank"
                                ><i class="fab fa-twitter"></i
                            ></a>
                        </li>
                        <li>
                            <a href="#" target="_blank"
                                ><i class="fab fa-youtube"></i
                            ></a>
                        </li>
                        <li>
                            <a href="#" target="_blank"
                                ><i class="fab fa-facebook-f"></i
                            ></a>
                        </li>
                        <li>
                            <a href="#" target="_blank"
                                ><i class="fab fa-linkedin-in"></i
                            ></a>
                        </li>
                        <li>
                            <a href="#" target="_blank"
                                ><i class="fab fa-instagram"></i
                            ></a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <span class="close-btn sidebar-modal-close-btn"
            ><i class="flaticon-cross-out"></i
        ></span>
    </div>
</div>
