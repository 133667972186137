import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { MatInputModule } from "@angular/material/input";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { PreloaderComponent } from "./components/layouts/preloader/preloader.component";
import { FooterComponent } from "./components/layouts/footer/footer.component";
import { HeaderOneComponent } from "./components/pages/header-one/header-one.component";
//  import { HomeFourComponent } from "./components/pages/home-four/home-four.component";
import { AboutComponent } from "./components/pages/about/about.component";
import { TeamComponent } from "./components/pages/team/team.component";
import { ErrorComponent } from "./components/pages/error/error.component";
import { ContactComponent } from "./components/pages/contact/contact.component";
import { HealthInsuranceComponent } from "./components/pages/health-insurance/health-insurance.component";
import { LifeInsuranceComponent } from "./components/pages/life-insurance/life-insurance.component";
import { FaqComponent } from "./components/pages/faq/faq.component";

// import { MatInputModule } from "@angular/material/input";
import { MatFormFieldModule } from "@angular/material/form-field";
import { FormsModule } from "@angular/forms";

@NgModule({
    declarations: [
        AppComponent,
         PreloaderComponent,
         FooterComponent,
         HeaderOneComponent,
        AboutComponent,
        TeamComponent,
         FaqComponent,
         ErrorComponent,
         ContactComponent,
         HealthInsuranceComponent,
         LifeInsuranceComponent,
    ],
    imports: [BrowserModule, AppRoutingModule],
    providers: [],
    bootstrap: [AppComponent],
})
export class AppModule {}
